export const wb_doc_methods = {
  methods: {
    close_all_panels() {
      // this.show_loader(true);
      console.log("close all panels");
      setTimeout(() => {
        this.panel_open = [];
        //  this.show_loader(false);
      }, 100);
    },
    table_format() {
      setTimeout(() => {
        var lst_td_p = document.querySelectorAll("table td p");
        for (let index = 0; index < lst_td_p.length; index++) {
          const element = lst_td_p[index];

          element.style.padding = "3px";
        }
        console.log("table format");
      }, 200);
    },
    onExpansionPanelClick(event) {
      // var targetID = event.currentTarget.id;
      // console.log("expand click");

      this.table_format();
      return true;
    },
    build_ti_list() {
      for (let index = 0; index < this.doc.lst_articles.length; index++) {
        const art = this.doc.lst_articles[index];
        if (art.lst_headers != undefined) {
          for (let index = 0; index < art.lst_headers.length; index++) {
            const hdr = art.lst_headers[index];

            if (hdr.lst_text_Items != undefined) {
              for (let index = 0; index < hdr.lst_text_Items.length; index++) {
                const ti = hdr.lst_text_Items[index];
                //  console.log("build ti list");
                this.ti_list.push(ti);
              }
            }
          }
        }
      }
    },
    add_link_click_handler(self) {
      console.log("call link handler");
      for (let iart = 0; iart < this.doc.lst_articles.length; iart++) {
        console.log("link search open panel");
        this.panel_open.push(iart);
      }

      console.log("name: " + self.rsrc);
      setTimeout(() => {
        let lst_links = document.querySelectorAll("a.tiptap-link");
        for (let index = 0; index < lst_links.length; index++) {
          const link = lst_links[index];
          link.addEventListener("click", function () {
            self.show_loader(true);
            // this.("debug");
            let formIn = {};
            formIn.email = self.$store.state.user.email;
            formIn.token = self.$store.state.user.token;
            formIn.href = link.href;
            formIn.resource_type = self.rsrc;

            formIn.apiname = "save_click_history";

            self.$store
              .dispatch("anyapi", formIn)
              .then((data) => {})
              .catch((msg) => {
                self.show_loader(false);
                self.swall_it(
                  "Error save_click_history",
                  msg,
                  "error",
                  null,
                  null
                );
              });
          });
        }
      }, 1000);

      //      this.panel_open=[];
    },

    search(activate) {
      if (this.$store.state.sterm != null && this.$store.state.sterm != "") {
        if (this.$store.state.sterm == this.saved_sterm) {
          this.close_all_panels();
          return true;
        } else {
          this.saved_sterm = this.$store.state.sterm;
        }
        const sterm = this.$store.state.sterm;
        //search
        //header-title
        //text-item-text
        var self = this;
        //this loop should open all panels
        for (let iart = 0; iart < this.doc.lst_articles.length; iart++) {
          console.log("search open panel");
          this.panel_open.push(iart);
        }
        setTimeout(() => {
          //self.mrbreakpoint("hdr hide");
          let lst_it = document.querySelectorAll(".text-item-text");
          console.log(": text items count:" + lst_it.length);
          let lst_hdr = document.querySelectorAll(".header-title");
          console.log(": hdr items count:" + lst_it.length);
          //hide all header
          for (let ihdr = 0; ihdr < lst_hdr.length; ihdr++) {
            const hdr = lst_hdr[ihdr];
            hdr.style.display = "none";
          }
          //hide all text
          for (let i_ti = 0; i_ti < lst_it.length; i_ti++) {
            const ti = lst_it[i_ti];
            ti.style.display = "none";
          }

          //show matches for header and reveal header and text
          for (let ihdr = 0; ihdr < lst_hdr.length; ihdr++) {
            const hdr = lst_hdr[ihdr];
            if (
              hdr.innerHTML.toLowerCase().indexOf(sterm.toLowerCase()) != -1
            ) {
              hdr.style.display = "block";
              console.log("hdrid " + hdr.id);
              //get parent hdr element
              let hdr_parent = document
                .querySelectorAll("[id='" + hdr.id + "']")[0]
                .closest("div .hdr_section");
              console.log(hdr_parent.innerHTML);
              // find all text in hdr parent and show them
              let lst_hdr_tits = hdr_parent.querySelectorAll(".text-item-text");
              console.log("lst_hdr_tits len " + lst_hdr_tits.length);
              for (let index = 0; index < lst_hdr_tits.length; index++) {
                const element = lst_hdr_tits[index];
                element.style.display = "block";
              }
            }
          }
          //show matches for text items and reveal header  text
          for (let i_ti = 0; i_ti < lst_it.length; i_ti++) {
            const ti = lst_it[i_ti];
            if (ti.innerText.toLowerCase().indexOf(sterm.toLowerCase()) != -1) {
              ti.style.display = "block";
              let tiparent = ti.closest("div");
              console.log("tiid " + tiparent.id);
              let header_parent = tiparent.closest("div");
              console.log("hdr2 id " + header_parent.id);
              header_parent.style.display = "block";
            }
          }

          for (let index = 0; index < this.ti_list.length; index++) {
            let ti = this.ti_list[index];
            if (
              ti.text_content.toLowerCase().indexOf(sterm.toLowerCase()) != -1
            ) {
              console.log("hit; " + ti.text_itemId);

              // ti.text_content = ti.text_content.replaceAll(
              //   sterm.toLowerCase(),
              //   '<span class="yellow">' + sterm.toLowerCase() + "</span>"
              // );
              // ti.text_content = ti.text_content.replaceAll(
              //   sterm.toUpperCase(),
              //   '<span class="yellow">' + sterm.toUpperCase() + "</span>"
              // );
              ti.text_content = ti.text_content.replaceAllNow(
                sterm,
                '<span class="yellow">' + sterm + "</span>"
              );
            }
          }
        }, 200);
      } else {
        //empty search string
        this.saved_sterm = "";
        let lst_yllw = document.querySelectorAll(".yellow");
        for (let index = 0; index < lst_yllw.length; index++) {
          const yllw = lst_yllw[index];
          yllw.classList.remove("yellow");
        }
        let lst_it = document.querySelectorAll(".text-item-text");
        console.log(": text items count:" + lst_it.length);
        let lst_hdr = document.querySelectorAll(".header-title");
        console.log(": hdr items count:" + lst_hdr.length);
        for (let ihdr = 0; ihdr < lst_hdr.length; ihdr++) {
          const hdr = lst_hdr[ihdr];
          hdr.style.display = "block";
        }
        //show all text
        for (let i_ti = 0; i_ti < lst_it.length; i_ti++) {
          const ti = lst_it[i_ti];
          ti.style.display = "block";
        }
      }
    },
    ti_del(ti) {
      var payload = {
        ti: this.mno(ti),
        rsrc: this.rsrc,
        panel: this.panel_open,
      };
      this.$emit("ti_del", payload);
      return true;
    },
    edit_ti(ti, hdr) {
      // this.mrbreakpoint(hdr);
      //capture the current Header for later saving TI
      var payload = {
        ti: this.mno(ti),
        hdr: this.mno(hdr),
        rsrc: this.rsrc,
        panel: this.panel_open,
      };
      this.$emit("edit_ti", payload);
      return true;
    },
    ti_add_up(ti, hdr) {
      // this.mrbreakpoint(ti);
      var payload = {
        ti: this.mno(ti),
        hdr: this.mno(hdr),
        rsrc: this.rsrc,
        panel: this.panel_open,
      };
      payload.ti.text_content = "";
      this.$emit("ti_add_up", payload);
    },
    ti_add_below(ti, hdr) {
      var payload = {
        ti: this.mno(ti),
        hdr: this.mno(hdr),
        rsrc: this.rsrc,
        panel: this.panel_open,
      };
      payload.ti.text_content = "";
      this.$emit("ti_add_below", payload);
    },
    ti_move_up(ti, hdr) {
      // this.mrbreakpoint(ti);
      var payload = {
        ti: this.mno(ti),
        hdr: this.mno(hdr),
        rsrc: this.rsrc,
        panel: this.panel_open,
      };
      console.log("ti_move_up");
      this.$emit("ti_move_up", payload);
    },
    ti_move_below(ti, hdr) {
      var payload = {
        ti: this.mno(ti),
        hdr: this.mno(hdr),
        rsrc: this.rsrc,
        panel: this.panel_open,
      };
      console.log("ti_move_below");
      this.$emit("ti_move_below", payload);
    },
    ti_add_first(hdr, rscr) {
      //    this.mrbreakpoint(hdr.headerid);
      var payload = { hdr: hdr, rscr: rscr };
      //payload.header.title = "";
      this.$emit("ti_add_first", payload);
      return true;
    },
    hdr_add_first(art, rscr) {
      var payload = { art: art, rscr: rscr };
      //payload.header.title = "";
      this.$emit("hdr_add_first", payload);
      return true;
    },

    hdr_del(hdr) {
      var payload = { hdr: hdr };
      //payload.header.title = "";
      this.$emit("hdr_del", payload);
      return true;
    },
    hdr_edit(hdr, art) {
      //newheader
      var payload = { hdr: hdr, art: art };
      //payload.header.title = "";
      this.$emit("hdr_edit", payload);
      return true;
    },
    hdr_move_up(hdr, art, rsrc) {
      var payload = { hdr: hdr, art: art, rsrc: rsrc };
      this.$emit("hdr_move_up", payload);
      return true;
    },
    hdr_move_down(hdr, art, rsrc) {
      var payload = { hdr: hdr, art: art, rsrc: rsrc };
      this.$emit("hdr_move_down", payload);
      return true;
    },
    hdr_add_up(hdr, art, rsrc) {
      //new
      var payload = { hdr: hdr, art: art, rsrc: rsrc };
      this.$emit("hdr_add_up", payload);
      return true;
    },
    hdr_add_below(hdr, art, rsrc) {
      //new
      var payload = { hdr: hdr, art: art, rsrc: rsrc };
      this.$emit("hdr_add_below", payload);
      return true;
    },
    art_del(article) {
      console.log("art_del");
      var payload = { article: article };
      //payload.header.title = "";
      this.$emit("art_del", payload);
      return true;
    },
    art_add_first(payload) {
      this.$emit("art_add_first", payload);
      return true;
    },
    art_move_up(article, rsrc) {
      console.log("art_move_up methods: " + rsrc);
      var payload = { article: article, rsrc: rsrc };
      //payload.header.title = "";
      this.$emit("art_move_up", payload);
      return true;
    },
    art_move_down(article, rsrc) {
      console.log("art_move_up methods: " + rsrc);
      var payload = { article: article, rsrc: rsrc };
      //payload.header.title = "";
      this.$emit("art_move_down", payload);
      return true;
    },
    art_add_up(article, rscr) {
      var art = this.mno(article);
      var payload = { article: art, rsrc: rscr };
      //  payload.article.title = "";
      this.$emit("art_add_up", payload);
      return true;
    },
    art_add_down(article, rscr) {
      var payload = { article: article, rsrc: rscr };
      // payload.article.title = "";
      this.$emit("art_add_down", payload);
      return true;
    },
    art_edit(article, rsrc) {
      console.log("Art_edit methods: " + rsrc);
      var payload = { article: article, rsrc: rsrc };
      //payload.header.title = "";
      this.$emit("art_edit", payload);
      return true;
    },
  },
};
